import React from "react"
import termsImg from "../assets//images/terms-of-service.jpg"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"

const TermsOfService = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Şartlar ve Koşullar"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Şartlar ve Koşullar"
      />
      <section className="terms-of-service-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg col-md">
              <div className="terms-of-service-content">
                <div className="privacy-policy-content">
                  <img src={termsImg} alt="pp" />
                  <p>
                    <br />
                    1. TARAFLAR
                    <br />
                    1.1. İşbu Çerçeve Sözleşme ("Sözleşme"); Alışverişlio
                    Fİnansal Teknoloji Ve Yazılım geliştirme Anonim Şirketi
                    (“Alışverişlio”) ile Alışverişlio tarafından sunulan
                    hizmetlerden faydalanan kişi ("Kullanıcı") arasında
                    akdedilmiştir. Alışverişlio ve Kullanıcı ayrı ayrı "Taraf"
                    ve birlikte "Taraflar" olarak anılacaklardır.
                    <br />
                    1.2. Alışverişlio’nın adres ve iletişim bilgisi aşağıdaki
                    gibidir; Adres: Burhaniye Mahallesi, Atilla Sokak, No:7-1,
                    Üsküdar / İstanbul Telefon numarası: 0 (212) 651 20 20
                    E-Posta adresi:
                    [info@alisverislio.com](mailto:info@alisverislio.com) Destek
                    Talepleri:
                    [destek@alisverislio.com](mailto:destek@alisverislio.com)
                    <br />
                    1.3.Kullanıcının kimliği ve iletişim bilgisi Kullanıcının
                    Alışverişlio nezdinde ödeme hesabı açarken yaptığı başvuruda
                    bildirdiği kimlik bilgisi ve iletişim bilgisidir.
                    <br />
                    1.4. Alışverişlio’nın yukarıda belirtilen adresi merkez
                    adresi olup Alışverişlio’nın bu adres dışında Hizmetlerini
                    sunduğu bir şubesi bulunmamaktadır. Alışverişlio’nın
                    temsilcilerinin güncel listesi ve bunların unvanları ile
                    adresleri
                    [www.alisverislio.com](http://www.alisverislio.com/)
                    adresinde yer almaktadır.
                    <br />
                    2. TANIMLAR
                    <br />
                    İşbu Sözleşmede aksi açıkça belirtilmedikçe aşağıda
                    listelenen ifadeler karşısında belirtilen anlamı
                    taşımaktadırlar.
                    <br />
                    Alıcı : Ödeme hizmetine konu fonun ulaşması istenen gerçek
                    veya tüzel kişiyi ifade eder.
                    <br />
                    Banka : 5411 sayılı Bankacılık Kanunu çerçevesinde faaliyet
                    gösteren bankaları ifade eder.
                    <br />
                    Elektronik Para : Kullanıcıdan alınan ve Alışverişlio
                    tarafından kabul edilen fon karşılığı ihraç edilen,
                    elektronik olarak saklanan, Alışverişlio dışında diğer
                    gerçek ya da tüzel kişiler tarafından kabul edilen ve
                    Kanun’da tanımlanan ödeme işlemlerini gerçekleştirmek için
                    kullanılan parasal değeri ifade eder.
                    <br />
                    Elektronik İletişim Araçları : İnternet ve diğer iletişim
                    ağları üzerinden iletilerin gönderilmesine, alınmasına veya
                    saklanmasına imkân sağlayan bilgisayar, telefon, faks,
                    otomatik arama makineleri gibi her türlü cihazı ifade eder.
                    <br />
                    Fon : Banknot, madeni para, kaydî para veya elektronik
                    parayı ifade eder.
                    <br />
                    Fona Çevirme : Elektronik paranın banknot, madeni para veya
                    kaydî paraya çevrilmesini ifade eder.
                    <br />
                    Gönderen : Kendi Ödeme hesabından veya Ödeme hesabı
                    bulunmaksızın ödeme emri veren gerçek veya tüzel kişiyi
                    ifade eder.
                    <br />
                    Hassas Ödeme Verisi : Ödeme Emrinin verilmesinde veya kimlik
                    doğrulamasında kullanılan, ele geçirilmesi veya
                    değiştirilmesi halinde dolandırıcılık ya da sahte işlem
                    yapılmasına imkân verebilecek şifre, güvenlik sorusu,
                    sertifika, şifreleme anahtarı ile PIN, kart numarası, son
                    kullanma tarihi, CVV2, CVC2 kodu gibi Ödeme Aracına ilişkin
                    kişisel güvenlik bilgilerini ifade eder.
                    <br />
                    Hizmetler : Alışverişlio’nın Mevzuat çerçevesinde ve sahip
                    olduğu faaliyet izinlerine uygun olarak sunduğu Kanun’un
                    12’nci maddesinde sayılan ödeme hizmetlerini ve elektronik
                    para işlemlerini ifade eder.
                    <br />
                    Hizmet Arayüzü : Kullanıcının Hizmetlerden yararlanmasını
                    sağlayan Internet Sitesi ve Mobil Uygulamayı ifade eder.
                    <br />
                    İletişim Bilgisi : Kullanıcı tarafından işbu Sözleşmenin
                    kurulması sırasında Alışverişlio’ya bildirilen ve sonrasında
                    değiştirilebilen, Kullanıcı ile iletişim kurulmasına yarayan
                    telefon numarası, elektronik posta adresi ve adres dahil
                    ancak bunlarla sınırlı olmamak üzere her türlü iletişim
                    bilgisini ifade eder.
                    <br />
                    İnternet Sitesi :
                    [www.alisverislio.com](http://www.alisverislio.com/)
                    adresinden Kullanıcının Kimlik Doğrulama adımlarını
                    tamamlamasıyla erişilebilen, Alışverişlio tarafından sunulan
                    hizmetlerin sunulduğu internet sitesini ifade eder.
                    <br />
                    İş Günü : Türkiye Cumhuriyeti Kanunları gereği bayram veya
                    tatil günü sayılmayan Pazartesi, Salı, Çarşamba, Perşembe,
                    Cuma günlerini ifade eder.
                    <br />
                    Kanun : 6493 sayılı Ödeme ve Menkul Kıymet Mutabakat
                    Sistemleri, Ödeme Hizmetleri ve Elektronik Para Kuruluşları
                    Hakkında Kanunu ifade eder.
                    <br />
                    Kart : Alışverişlio tarafından ihraç edilen ve Kullanıcının
                    kart tabanlı bir ödeme işlemini başlatmasını sağlamak için
                    gerekli ödeme uygulamalarını içeren ve ödeme aracı olarak
                    kullanılan fiziki kartları ve aynı işleve sahip sanal
                    kartları ifade eder.
                    <br />
                    Kart Hamili : Kendisine Alışverişlio tarafından Kart verilen
                    Kullanıcıyı ifade eder.
                    <br />
                    Kimlik Bilgileri : Kullanıcı tarafından işbu Sözleşmenin
                    kurulması sırasında Alışverişlio’ya bildirilen ve sonrasında
                    değiştirilebilen, ad, soyadı, doğum tarihi, doğum yeri, anne
                    adı, kimlik numarası, kimlik belgesi seri numarası dahil
                    ancak bunlarla sınırlı olmamak üzere Kullanıcının kimliğinin
                    belirlenmesine yarayan her türlü bilgiyi ifade eder.
                    <br />
                    Kimlik Doğrulama : Bildirilen herhangi bir kimlik bilgisinin
                    gerçekten bildiren kişiye ait olduğuna dair güvence sağlayan
                    mekanizmayı ifade eder.
                    <br />
                    Kişisel Güvenlik Bilgileri : Ödeme aracı ile işlem
                    gerçekleştirirken kullanılabilecek şifre, son kullanma
                    tarihi, güvenlik numarası gibi ödeme aracını ve ödeme aracı
                    kullanıcısının kimliğini belirleyici bilgileri ifade eder.
                    <br />
                    Kullanıcı : Alışverişlio nezdinde ödeme hesabı açarak
                    Alışverişlio tarafından sunulan ödeme hizmetlerinden,
                    elektronik para hizmetlerinden ve/veya diğer hizmetlerden
                    faydalanan, işbu Sözleşmenin tarafı olan gerçek kişiyi ifade
                    eder.
                    <br />
                    Kullanıcı Güvenlik Bilgileri : Kimlik doğrulama işleminin
                    yapılması amacıyla Alışverişlio tarafından Kullanıcıya
                    verilen veya Kullanıcı tarafından belirlenerek Alışverişlio
                    ile mutabık kalınan özelleştirilmiş bilgiyi ifade eder.
                    <br />
                    Merkez Bankası : Türkiye Cumhuriyet Merkez Bankası’dır.
                    <br />
                    Mevzuat : Kanun, Kanuna dayanılarak çıkarılan ilgili ikincil
                    düzenlemeler ve işbu Sözleşme ve Alışverişlio tarafından
                    sunulan hizmetlere doğrudan uygulanabilen Türkiye
                    Cumhuriyeti kanunları, yönetmelikleri ve diğer düzenlemeleri
                    ifade eder.
                    <br />
                    Mobil Uygulama : Akıllı telefon veya tablet gibi mobil bir
                    cihaza yüklenerek, Kullanıcının Kimlik Doğrulama adımlarını
                    tamamlamasıyla Alışverişlio tarafından sunulan hizmetlere
                    erişimi ve hizmetlerin kullanılmasını sağlayan uygulamadır.
                    <br />
                    Ödeme Aracı : Kullanıcı tarafından Ödeme Emrini vermek için
                    kullanılan kart, cep telefonu, QR kodu, şifre ve benzeri
                    kişiye özel araçlardır.
                    <br />
                    Ödeme Emri : Kullanıcı tarafından ödeme işleminin
                    gerçekleşmesi amacıyla Alışverişlio’ya veya başka bir ödeme
                    hizmeti sağlayıcısına verilen talimattır.
                    <br />
                    Kart Şeması : Alışverişlio’nın işleyişini destekleyen
                    altyapı ve ödeme sisteminden bağımsız olarak, bu işleyişin
                    yürütülmesinden sorumlu yönetim ve karar organları ile
                    organizasyon yapısı da dâhil olmak üzere, kartlı ödeme
                    işlemlerinin gerçekleştirilmesini sağlayan kurallar,
                    uygulamalar, rehberler ve standartlar bütününü ifade eder.
                    <br />
                    Ödeme Hesabı : Kullanıcı adına Alışverişlio nezdinde açılan
                    ve Ödeme Hizmetinin sunulmasında kullanılan hesaptır.
                    <br />
                    3. SÖZLEŞMENİN KONUSU VE KAPSAMI
                    <br />
                    İşbu Sözleşme, Kanun kapsamında sahip olduğu faaliyet izni
                    çerçevesinde Alışverişlio tarafından verilmekte olan
                    Hizmetlerden, Kullanıcının faydalanabilmesine ilişkin
                    koşulları ve Tarafların bu kapsamdaki hak ve
                    yükümlülüklerini düzenlemek üzere akdedilmiştir.
                    <br />
                    BÖLÜM 2
                    <br />
                    TARAFLARIN HAK ve YÜKÜMLÜLÜKLERİ
                    <br />
                    4. KULLANICININ HAK ve YÜKÜMLÜLÜKLERİ
                    <br />
                    4.1. Alışverişlio tarafından sunulan Hizmetlerden
                    faydalanmak isteyen gerçek kişiler, Hizmet Arayüzü üzerinden
                    asgari şartları Mevzuat çerçevesinde belirlenmiş olan ve
                    Alışverişlio tarafından şart koşulan kimlik tespiti ve
                    kimlik teyidi adımlarını tamamlayarak hesap açmak
                    zorundadırlar. Hesap açma adımını tamamlamış gerçek kişiler
                    işbu Sözleşme çerçevesinde Kullanıcı kabul edilirler.
                    <br />
                    4.2. Alışverişlio’nın Kullanıcıya Hizmetleri sunabilmesi
                    için Kullanıcı tarafından sunulması gereken Kimlik
                    Bilgilerinin ve İletişim Bilgilerinin bizzat Kullanıcı
                    tarafından doldurulmuş olması gerekir. Mevzuat gereği ergin
                    olmayanların işbu Sözleşmeyi akdetmesi için yasal
                    temsilcinin onayının alınmasına ilişkin kurallar saklı olmak
                    üzere, işbu Sözleşme vekalet veya yasal temsil yoluyla
                    akdedilemez. Kullanıcı, yararlanmak istediği Hizmetlere
                    ilgili alanlara manuel olarak veri girişi yaparak yalnızca
                    kendisi tarafından bilinebilecek bilgileri belirtecek ve
                    sunulması beklenilen belgeleri Hizmet Arayüzü üzerinden
                    yapılan yönlendirmelere uygun olarak iletecektir.
                    Alışverişlio, iletilen bilgi ve belgelerin doğruluğunu teyit
                    etmesini takiben Kullanıcıya hizmet vermeye başlayacaktır.
                    <br />
                    4.3. Kullanıcı, hesap açılış adımlarında sunduğu kimlik
                    bilgilerinin ve iletişim bilgilerinin doğruluğundan
                    sorumludur. Alışverişlio, iç kontrol ve uyum faaliyetleri
                    kapsamında yürüttüğü faaliyetler sırasında Kullanıcının
                    kabulü aşamasında veya daha sonra sunulan kimlik
                    bilgilerinin ve/veya iletişim bilgilerinin gerçeğe aykırı
                    olmasından veya bu bilgilerin yetkisiz kişilerce kullanılmış
                    olduğundan şüphe duyarsa Kullanıcıdan kimlik bilgilerinin
                    ve/veya iletişim bilgilerinin teyit etmesini isteyebilir. Bu
                    durumda, Kullanıcı Kimlik Bilgilerinin ve/veya İletişim
                    Bilgilerinin doğruluğunun teyidi için Alışverişlio
                    tarafından istenen her türlü bilgi ve belgeyi sunmakla
                    yükümlüdür.
                    <br />
                    4.4. Kullanıcı Alışverişlio tarafından sunulan hiçbir
                    Hizmetten faydalanırken kendi adına ve fakat başkası
                    hesabına hareket etmediğini ve Sözleşme’nin yürürlükte
                    kaldığı süre boyunca da başkası hesabına edemeyeceğini
                    kabul, beyan ve taahhüt eder. Buna göre, Kullanıcı kendisine
                    Hizmet Arayüzüne erişim ve Hizmetlerden faydalanması için
                    sağlanan şifreleri, parolaları ve diğer erişim bilgilerini
                    hiçbir suretle üçüncü kişilerle paylaşamaz, üçüncü kişilerin
                    Hizmet Arayüzünü kullanarak kendi adına işlem yapmasına izin
                    veremez. Kullanıcı bu hükmü ihlal etmesi halinde 5549 sayılı
                    Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanunun
                    15’inci maddesi uyarınca altı aydan bir yıla kadar hapis
                    cezası ve beşbin güne kadar adli para cezası
                    uygulanabileceğini bildiğini beyan eder. Bu madde, Suç
                    Gelirlerinin Aklanmasının ve Terörün Finansmanının
                    Önlenmesine Dair Tedbirler Hakkında Yönetmelik’in 17’nci
                    maddesi uyarınca Kullanıcı tarafından verilecek yazılı
                    beyanın yerine geçer.
                    <br />
                    4.5. Kullanıcı, Alışverişlio’nın hizmetlerini yasalara veya
                    ahlaka aykırı amaçlarla, bu şekilde kullanılabilecek veya
                    doğrudan yasalara veya ahlaka aykırı olan mal veya
                    hizmetlerin temini amacıyla kullanamaz. Kullanıcı, mevzuat
                    gereği aranan kimlik tespiti için gerekli bilgi ve belgeleri
                    sunmadan ve kimlik teyidi için gerekli adımları tamamlamadan
                    Alışverişlio’nın hizmetlerini yalnızca mal veya hizmet alımı
                    için kullanacağını beyan eder.
                    <br />
                    4.6. Ergin olmayan Kullanıcıların Hizmetlerden
                    faydalanabilmesi için yasal temsilcileri tarafından
                    Alışverişlio’nın belirlediği yöntemler kullanılmak suretiyle
                    onay verilmiş olması şarttır. Ergin olmayan kullanıcıların
                    elektronik para hesapları yasal temsilcileri tarafından
                    takip edilebilir.
                    <br />
                    4.7. Kullanıcı işbu Sözleşmedeki bilgi ve koşullara dilediği
                    zaman Hizmet Arayüzü üzerinden erişim hakkına sahiptir.
                    <br />
                    5. Alışverişlio’NIN HAK ve YÜKÜMLÜLÜKLERİ
                    <br />
                    5.1. Alışverişlio, tabi olduğu mevzuat ve kendi risk
                    yönetimi politikası kapsamında Kullanıcıları için farklı
                    kimlik tespiti yöntemleri ve farklı hesap türleri sunabilir.
                    Alışverişlio tarafından sunulan hizmetler ile bu hizmetlere
                    ilişkin limitler ve kısıtlamalar yine tabi olunan mevzuat ve
                    risk yönetimi politikaları kapsamında farklılaştırılabilir.
                    Alışverişlio sunduğu Hizmetleri tamamen kendi ihtiyarında
                    olarak her zaman değiştirebilir, sonlandırabilir.
                    <br />
                    5.2. Alışverişlio’nın sorumluluğu işbu Sözleşme ile tanımlı
                    Hizmetlerin (ödeme hizmetleri, elektronik para ihracı, para
                    transferi, kart tabanlı ödemeler ve benzeri) sunulması ile
                    sınırlıdır. Alışverişlio Hizmetlerini kullanarak
                    Kullanıcının üçüncü kişilerden edineceği mal ve/veya
                    hizmetlerin bedellerini ödemesi halinde, Alışverişlio hiçbir
                    şekilde üçüncü kişiler tarafından sunulan, sağlanan, satılan
                    veya taahhüt edilen mal ve/veya hizmetleri ayıptan ari bir
                    şekilde verileceği, sağlanacağı veya temin edileceği yönünde
                    bir taahhütte bulunmaz. Buna göre, Alışverişlio üçüncü
                    kişiler tarafından mal ve/veya hizmetlerin yasalara ve
                    ahlaka uygun olması, eksiksiz olarak temin veya teslim
                    edilmesi, fatura, irsaliye, garanti belgesi vb. yasal
                    düzenlemelere göre zorunlu belgelerin Kullanıcıya sunulması
                    ve benzeri diğer yasal yükümlülüklerden sorumlu tutulamaz.
                    Kullanıcı, bu madde kapsamında üçüncü kişilerin sorumlu
                    olduğu belirtilen iş ve işlemler nedeniyle çıkabilecek
                    hiçbir ihtilafta Alışverişlio’nın taraf olmadığını,
                    Alışverişlio’dan bu sebeplerle herhangi bir talepte
                    bulunamayacağını bilmekte ve kabul etmektedir.
                    <br />
                    5.3. Alışverişlio, Mevzuata uygun şekilde kapsam ve
                    kurallarını kendi belirleyeceği iç kontrol ve uyum
                    faaliyetleri kapsamında
                    <br />
                    (i) Kullanıcının hesap açılışı sırasında veya sonrasında
                    sunduğu Kimlik Bilgilerinin ve İletişim Bilgilerinin
                    doğruluğunu, (ii) Kullanıcının başkası hesabına hareket edip
                    etmediğini, (iii) Hizmetleri kullanması için kendisine
                    verilen Hassas Ödeme Verisi dahil her türlü şifre ve erişim
                    yetkisini bir menfaat karşılığı üçüncü kişilere verip
                    vermediğini, (iv) Hizmetleri yasa dışı faaliyetler için
                    kullanıp kullanmadığını (v) Hizmetler kapsamında
                    gerçekleştirilen işlemlerin, hileli veya yetkisiz bir
                    şekilde yapılıp yapılmadığını (vi) Ödeme Hesabına hileli
                    veya yetkisiz erişim sağlanıp sağlanmadığını veya hileli
                    veya yetkisiz bir şekilde ödeme işlemi başlatılmaya
                    çalışılıp çalışılmadığını denetleyebilir, Kullanıcının
                    işlemlerini bu amaçla gözlemleyebilir, raporlayabilir ve
                    kaydedebilir.
                    <br />
                    5.4. Alışverişlio’nın Madde 5.3. kapsamında yürüteceği
                    faaliyetler kapsamında elde edeceği bulgular veya ortaya
                    çıkan makul şüphenin neticesi olarak Alışverişlio tamamen
                    kendi ihtiyarında olmak üzere
                    <br />
                    (i) Hizmetlerin tümünü veya bazılarını askıya alabilir, (ii)
                    Kullanıcının Elektronik Paranın geri ödenmesi talebini
                    erteleyebilir veya reddedebilir, (iii) Kullanıcıya sunulan
                    Hizmetleri dondurabilir, durdurabilir veya kapatabilir, (iv)
                    Sözleşmeyi herhangi bir ihbar süresine bağlı olmaksızın
                    derhal feshedebilir.
                    <br />
                    5.5. Alışverişlio, Mevzuatta yer alan bilgi verilmesini
                    engelleyici hükümler ile güvenliği tehdit edici objektif
                    nedenlerin bulunması hali dışında, Madde 5.4 kapsamında
                    gerçekleştireceği işlemler hakkında – mümkün olması
                    durumunda işlemin uygulanmasından önce, mümkün değilse
                    işlemin gerçekleşmesinden sonra – Kullanıcıyı
                    bilgilendirecektir.
                    <br />
                    5.6. Kullanıcı, Alışverişlio'nın önceden yazılı izni olmadan
                    Alışverişlio’ya ait hiçbir markayı, fikri veya sınai hakkı
                    kopyalayamaz, taklit edemez veya kullanamaz. Hizmet
                    Arayüzünde kullanılan tüm sayfa üst bilgileri, özel
                    grafikler, düğme simgeleri ve yazılar Alışverişlio'nın
                    markaları ve/veya ticari sunuş tarzıdır. Internet Sitesi ve
                    Mobil Uygulamaya ilişkin tüm haklar, unvanlar ve menfaatler,
                    buradaki herhangi bir içerik, Hizmetlere ilişkin teknoloji
                    ve yukarıda bahsedilenlerden oluşturulan ya da türetilen
                    herhangi bir teknoloji ya da tüm teknolojiler ve içerik
                    Alışverişlio'nın özel mülkiyetindedir. Kullanıcı,
                    Alışverişlio’nın önceden yazılı izni olmadan bunları
                    kopyalayamaz, taklit edemez veya kullanamaz.
                    <br />
                    BÖLÜM 3
                    <br />
                    HİZMETLERE İLİŞKİN GENEL HÜKÜMLER
                    <br />
                    6. HİZMETLERİN BELİRLENMESİ
                    <br />
                    6.1. Alışverişlio, Mevzuata uygun şekilde ve Merkez Bankası
                    tarafından verilen faaliyet izinleri çerçevesinde Hizmetler
                    sunacaktır. Kullanıcı, ilgili Hizmet için öngörülen şart ve
                    koşullara uymak ve o Hizmet için bir ücret öngörülmüşse o
                    ücreti ödemek koşuluyla Alışverişlio tarafından sunulan
                    Hizmetlerden yararlanabilir.
                    <br />
                    6.2. Alışverişlio, Sözleşmenin yürürlüğe girdiği sırada
                    sunmakta olduğu hiçbir Hizmeti Sözleşmenin devam ettiği
                    sürece değiştirmeden devam edeceğini taahhüt etmez.
                    Alışverişlio, sunduğu Hizmetlerin içeriğini, faydalanma
                    koşullarını ve ücretlerini tek taraflı olarak Mevzuatta şart
                    koşulmuşsa önceden haber vermek şartıyla değiştirebilir;
                    Hizmetleri geçici olarak veya tamamen durdurabilir.
                    <br />
                    7. HİZMETLERİN KULLANILMASI İÇİN KULLANILACAK BİLGİLER
                    <br />
                    7.1. Hizmetlerin kullanılmaya başlanmasından önce,
                    Kullanıcıya ödeme işleminin başlatılması ve
                    gerçekleştirilmesi dahil tüm Hizmetlerden faydalanabilmesi
                    için Hizmet Arayüzünü kullanabilmesine izin verecek olan,
                    kolay tahmin edilemeyen, üçüncü kişilerle paylaşmayacağı,
                    ilgili Hizmet Arayüzü üzerinden yapılan yönlendirmelere
                    uygun olarak bir şifre belirler.
                    <br />
                    7.2. Kullanıcı Internet Sitesi ve/veya Mobil Uygulama
                    üzerinden kendisine yapılacak yönlendirmeler doğrultusunda,
                    sunulan seçenekleri kullanarak ilgili Hizmet Arayüzünü
                    kullanabilmek için, tercih edeceği Hizmet Arayüzüne erişim
                    yöntemine göre TC kimlik numarası, Alışverişlio tarafından
                    verilen hesap numarası, telefon numarası, e-posta adresi
                    bilgilerinden en az birini; madde 7.1. gereğince
                    oluşturacağı şifreyi, Kimlik Doğrulama yöntemi olarak
                    kullanılacak başka bir veriyi ve/veya cep telefonuna
                    gönderilecek tek seferlik şifreleri kullanacaktır.
                    <br />
                    7.3. Kullanıcının Alışverişlio’dan Kart talep etmesi Kart
                    ile yapacağı ödeme işlemleri ile para yatırma ve çekme
                    işlemlerinde; (i) işlemin fiziki bir POS cihazı veya ATM ile
                    yapılması halinde Kartı ve yapacağı işlem zorunlu kılıyorsa
                    Kartın kullanımı için kendisi tarafından belirlenen Kart
                    şifresini (ii) internet üzerinden yapılan işlemlerde ise
                    kendisine verilen fiziki veya sanal karta ait kart
                    bilgilerini ve yapılan işlem için 3D güvenli ödeme yöntemi
                    kullanılacaksa Alışverişlio’ya bildirmiş olduğu cep telefonu
                    numarasına gönderilecek tek seferlik şifreyi kullanacaktır.
                    <br />
                    7.4. Alışverişlio, Kullanıcının gerçekleştireceği işlemlerin
                    güvenliğini sağlamak ve güçlendirmek, kullanıcı deneyimini
                    daha iyi hale getirmek ve Mevzuatta öngörülen
                    yükümlülüklerini yerine getirebilmek için ödeme işlemlerinin
                    başlatılması ve gerçekleştirilmesi için Kullanıcıdan ilave
                    bilgi ve belgeler talep edebilir, Kullanıcının Hizmet
                    Arayüzüne erişimine ilişkin yeni kurallar getirebilir.
                    <br />
                    8. ÜCRETLER
                    <br />
                    8.1. İşbu Sözleşme kapsamında sunulan hizmetlerle ile ilgili
                    Kullanıcının Alışverişlio’ya ödemesi gereken ücretler,
                    EK-1’de yer alan ve işbu Sözleşme’nin de eki ve ayrılmaz
                    parçası niteliğindeki olan Ücret Bilgilendirme Listesinde
                    yer almaktadır.
                    <br />
                    8.2. Herhangi bir işlem için ücret alınması öngörülmüşse,
                    işlem ücreti Kullanıcının ödeme hesabından alınır. İşlem
                    ücreti ödeme işlemi kapsamında bir tutarın aktarılması için
                    alınıyorsa, aktarılan tutar üzerinden herhangi bir kesinti
                    yapılmaz. Aktarılan tutar sonrası ödeme hesabında kalan
                    bakiye, alınacak işlem ücretinden az ise Alışverişlio işlemi
                    reddedebilir.
                    <br />
                    8.3. Alışverişlio, ürün ve hizmetler ile işlemlerden
                    alınacak ücretleri; ilgili mevzuatta yer alan değişiklik
                    esasına uygun olarak, tek taraflı olarak
                    değiştirilebilir/artırılabilir.
                    <br />
                    9. ÖDEMENİN GERÇEKLEŞTİRİLECEĞİ PARA BİRİMİ
                    <br />
                    9.1. İşlemin her iki tarafının da Türkiye’de yerleşik olduğu
                    ve Türkiye’de bulunan ödeme hizmeti sağlayıcılarının
                    kullanıldığı ödeme işlemleri sadece Türk Lirası ile
                    gerçekleştirilebilir.
                    <br />
                    9.2. İşlemin taraflarından birinin yurt dışında bulunduğu
                    ödemeler ile Mevzuat çerçevesinde yabancı para ile
                    yapılmasına izin verilen ödeme işlemlerinde Mevzuata uygun
                    olmak kaydıyla, ilgili Hizmet çerçevesinde Alışverişlio
                    tarafından belirlenen para birimi kullanılarak
                    gerçekleştirilir. Referans döviz kuru, Merkez Bankası
                    tarafından ilan edilen ilgili döviz kurunun belirli bir
                    oranda artırılması suretiyle hesaplanır. Alışverişlio
                    tarafından uygulanan döviz karşılığı Türk Lirası tutar
                    ilgili işleme ilişkin dekontta gösterilir. Mevzuat
                    çerçevesinde ücretler ve kullanılan döviz kuru hakkında
                    işlemden önce bilgi verilmesinin zorunlu olduğu hallerde,
                    Kullanıcı ilgili Hizmeti bu bilgiler ışığında kabul etmiş
                    sayılır.
                    <br />
                    10. HATALI VEYA YETKİSİZ İŞLEMLER
                    <br />
                    10.1. Kullanıcı yetkilendirmediği veya hatalı
                    gerçekleştirilmiş ödeme işlemini öğrendiği andan itibaren
                    gecikmeksizin Alışverişlio’ya bildirmek suretiyle işlemin
                    düzeltilmesini isteyebilir. Düzeltme talebi, her halükarda
                    ödeme işleminin gerçekleştirilmesinden itibaren 13 (on üç)
                    ay içinde yapılır. Kullanıcının düzeltme talebi,
                    Alışverişlio tarafından mümkün olan en kısa süre içerisinde
                    yerine getirilir.
                    <br />
                    10.2. Kullanıcı, ödeme aracını teslim aldığı veya ödeme
                    aracı üzerinde kullanım ve tasarruf imkanına sahip olduğu
                    andan itibaren, gerekli güvenlik tedbirlerini almakla
                    yükümlüdür. Kullanıcı, ödeme aracı ile ilgili Hassas Ödeme
                    Verisi bilgilerinin korunmasına yönelik gerekli önlemleri
                    almak ve ödeme aracını ihraç ve kullanım koşullarına uygun
                    olarak kullanmakla yükümlüdür. Ödeme aracının kaybolması,
                    çalınması veya iradesi dışında gerçekleşmiş herhangi bir
                    işlemi öğrenmesi halinde, Kullanıcı durumu derhal
                    Alışverişlio’ya bildirir.
                    <br />
                    10.3. Kayıp veya çalıntı bir ödeme aracının kullanılması ya
                    da müşteri güvenlik bilgilerinin gereği gibi muhafaza
                    edilmemesi nedeniyle ödeme aracının başkaları tarafından
                    kullanılması durumunda, Kullanıcı, Madde 10.2’ye göre
                    yaptığı bildirimden önceki 24 (yirmi dört) saat içinde
                    gerçekleşen yetkilendirmediği ödeme işlemlerinden doğan
                    zararın 250,- (iki yüz elli) Türk Lirasına kadar olan
                    bölümünden sorumludur. Kullanıcı, Madde 10.2 uyarınca
                    yaptığı bildirimden sonra gerçekleşen yetkilendirmediği
                    ödeme işlemlerinden sorumlu tutulamaz. Kullanıcı, ödeme
                    aracının başkaları tarafından kullanılması sonucunda ortaya
                    çıkan zararın sebebinin ödeme hizmeti sağlayıcısının
                    çalışanı, temsilcisi veya dış hizmet aldığı kuruluş olması
                    durumunda, bu fıkra uyarınca herhangi bir ödeme işleminden
                    veya ödeme işlemi nedeniyle oluşan zarardan sorumlu
                    tutulamaz. Hatalı veya yetkilendirilmemiş ödeme işlemi ile
                    ilgili olarak Kullanıcının ödeme aracını hileli kullanması
                    veya kasten veya ağır ihmalle ödeme aracı ile ilgili
                    güvenlik bilgilerinin korunmasına yönelik gerekli önlemleri
                    almaması ya da ödeme aracını ihraç ve kullanım koşullarına
                    uygun olarak kullanmaması durumunda, yetkilendirilmemiş
                    ödeme işleminden doğan zararın tamamından sorumludur.
                    <br />
                    10.4. Kullanıcının Madde 10.3’te yer alan hususların
                    oluştuğuna dair kuvvetli şüphe bulunması durumunda,
                    Alışverişlio işleme ilişkin düzeltme yapmadan önce, makul
                    bir süreyle sınırlı olmak üzere, araştırma yapma hakkına
                    sahiptir.
                    <br />
                    10.5. Kullanıcı düzeltme talebinin Alışverişlio tarafından
                    uygun bulunması halinde ilgili işlem bedeli Alıcıya
                    aktarılmayarak Alışverişlio tarafından mümkün olan en kısa
                    süre içerisinde doğrudan Kullanıcıya iade edilecektir.
                    Kullanıcının düzeltme talebini Alışverişlio’ya bildirdiği
                    anda işlem bedeli Alıcıya aktarılmış ise Alışverişlio
                    Alıcıdan ilgili tutarların Kullanıcıya iade edilmesini talep
                    edecektir. Alıcının, ilgili tutarı Kullanıcıya iade etmesini
                    kabul etmesi halinde bu tutar Kullanıcıya iade edilecektir.
                    Alıcının, ilgili tutarı Kullanıcıya iade etmeyi kabul
                    etmemesi halinde, Alışverişlio’nın tutarın iadesi ile ilgili
                    sorumluluğu sona erecek olup, Kullanıcının ödeme işlemi
                    neticesinde Alıcıya aktarılan tutarı doğrudan Alıcıdan talep
                    etmesi gerekecektir.
                    <br />
                    11. KULLANICI CİHAZININ TAŞIMASI GEREKEN TEKNİK ÖZELLİKLER
                    <br />
                    11.1. Kullanıcının Alışverişlio tarafından sunulan
                    Hizmetlerden faydalanabilmesi için Hizmet Arayüzüne
                    erişebilmesine ve Hizmet Arayüzünü kullanabilmesine olanak
                    sağlayan cihazlara sahip olması gerekmektedir.
                    <br />
                    11.2. Kullanıcının İnternet Sitesini kullanabilmesi için
                    internete bağlanabilen ve temel internet sitelerinde
                    gezinmeye olanak sağlayan bir internet tarayıcının yüklü
                    olduğu bir masaüstü bilgisayar, dizüstü bilgisayar, tablet,
                    akıllı telefon veya benzeri bir cihaza sahip olması
                    gerekmektedir.
                    <br />
                    11.3. Kullanıcının Mobil Uygulamasını kullanabilmesi için
                    internete bağlanabilen bir akıllı telefon kullanması
                    gerekir. Akıllı telefonların iOS, Android, Huawei veya
                    Hizmet Arayüzünün güvenli kullanımına izin veren işletim
                    sistemleri ile çalışması tavsiye edilir. Her bir mobil cihaz
                    için ilgili uygulama mağazası üzerinden Mobil Uygulamanın
                    kurulması gerekecektir. Telefonun uygulamanın yüklenmesi
                    için gerekli hafızaya sahip olması gerekir. Alışverişlio
                    müşteri deneyimini iyileştirmek için Mobil Uygulamanın
                    güncellenmesini isteyebilir. Mobil Uygulamanın güncellenmesi
                    halinde kullanılan akıllı telefonda bu güncellemelerin
                    yapılmasını mümkün kılacak işletim sistemi güncellemelerin
                    yapılmasına izin verecek nitelikte olması gerekir.
                    <br />
                    11.4. Kullanıcı Hizmet Arayüzünü kullanırken (i) cihazının
                    internet bağlantısının güvenli olmasından, (ii) cihazın
                    güvenliğini sağlayan virüs koruma yazılımlarının
                    kurulmasından ve kullanılmasından, (iii) cihazın üçüncü
                    kişilerin eline geçmesinin engellenmesi ilişkin gerekli
                    tedbirlerin alınmasından sorumludur. Kullanıcının Hizmet
                    Arayüzüne erişim için kullandığı cihazın yetkisiz kişilerce
                    kullanılması veya kontrolünün eline geçirilmesi nedeniyle
                    oluşabilecek hiçbir zarardan Alışverişlio sorumlu
                    olmayacaktır.
                    <br />
                    BÖLÜM 4
                    <br />
                    HİZMETLER
                    <br />
                    12. ELEKTRONİK PARA İHRACI VE GERİ ÖDEME
                    <br />
                    12.1. Kullanıcının Alışverişlio nezdindeki ödeme hesabına
                    Elektronik Para ihracı için fon aktarmasından sonra
                    Alışverişlio tarafından gecikmeksizin Elektronik Para ihraç
                    edilir. Kullanıcı, Alışverişlio’nın elektronik para
                    karşılığında aldığı fonun tutarını gösteren dekonta Hizmet
                    Arayüzü aracılığıyla erişebilecek ya da kalıcı veri
                    saklayıcısı aracılığıyla kendisine iletilmesini
                    isteyebilecektir.
                    <br />
                    12.2. Elektronik para karşılığında alınan fon için faiz
                    işletilemez ve müşteriye elektronik paranın tutulduğu süreye
                    ve tutara bağlı herhangi bir menfaat sağlanamaz.
                    <br />
                    12.3. Kullanıcı elektronik paranın bir kısmının veya
                    tamamının geri ödenmesini talep edebilir. Alışverişlio, işbu
                    Sözleşmenin ilgili hükümleri ve Mevzuat’ın verdiği yetkiler
                    ve koyduğu yükümlülükler saklı kalmak kaydıyla, müşterinin
                    talebi üzerine elektronik paranın karşılığı kadar fonun geri
                    ödenmesine ilişkin işlemleri gecikmeksizin ve her halükarda
                    talebi almasının ardından en geç ertesi iş günü sonuna kadar
                    yapmakla yükümlüdür.
                    <br />
                    12.4. Alışverişlio, Kullanıcının Elektronik Paranın geri
                    ödenmesini, (i) işbu Sözleşmenin sona ermesinden önce talep
                    etmesi halinde, (ii) Madde 12.7.’de belirtilen son kullanma
                    tarihinden önce talepte bulunması halinde, (iii) Madde
                    12.7.’de belirtilen son kullanma tarihinden itibaren bir
                    yıldan uzun bir süre geçtikten sonra talep edilmesi halinde
                    bu işlemin maliyetiyle orantılı bir ücret talep edebilir.
                    <br />
                    12.5. Alışverişlio, elektronik paranın karşılığı olan
                    fonların, Kullanıcının Alışverişlio hesabına tanımlı bir
                    banka hesabına ödenmesini şart koşabilecektir. Elektronik
                    para karşılığı fonun kredi kartı ile ödendiği durumlarda
                    ise, elektronik paranın geri ödemesi Mevzuat hükümleri
                    çerçevesinde kısıtlanabilir.
                    <br />
                    12.6. Kullanıcının Elektronik Paranın bir Banka nezdindeki
                    hesaba veya başka bir elektronik para kuruluşu ya da ödeme
                    hizmet sağlayıcı nezdindeki hesaba geri ödenmesini istemesi
                    ya da bir ATM aracılığıyla nakit olarak geri ödenmesini
                    istediği durumlarda Alışverişlio ilgili Banka ya da diğer
                    kuruluştan kaynaklanan gecikmelerden sorumlu tutulamaz.
                    <br />
                    12.7. Elektronik paranın son kullanım tarihi, Kullanıcının
                    hesabında yaptığı son işlemi (para çekme, para yatırma,
                    ödeme ve benzeri) izleyen bir yıllık sürenin dolduğu
                    tarihtir.
                    <br />
                    13. ÖDEME HİZMETLERİ
                    <br />
                    13.1. İşbu Sözleşme kapsamında Alışverişlio’nın faaliyet
                    izni almış olması şartıyla Alışverişlio tarafından sunulan
                    (i) ödeme hesabına para yatırılması ve ödeme hesabından para
                    çekilmesine imkan veren hizmetler de dâhil olmak üzere ödeme
                    hesabının işletilmesi için gerekli tüm işlemler; (ii)
                    Kullanıcının Alışverişlio nezdinde bulunan ödeme hesabındaki
                    fonun aktarımını içeren, bir defaya mahsus olanlar da dâhil
                    doğrudan borçlandırma işlemi, ödeme kartı ya da benzer bir
                    araçla yapılan ödeme işlemi ile düzenli ödeme emri dâhil
                    para transferi; (iii) ödeme aracının ihraç veya kabulü, (iv)
                    Para havalesi, (v) Fatura ödemelerine aracılık edilmesine
                    yönelik hizmetler; (vi) Kullanıcının isteği üzerine başka
                    bir ödeme hizmeti sağlayıcısında bulunan ödeme hesabıyla
                    ilgili sunulan ödeme emri başlatma hizmeti, (vii)
                    Kullanıcının onayının alınması koşuluyla, Kullanıcının ödeme
                    hizmeti sağlayıcıları nezdinde bulunan bir veya daha fazla
                    ödeme hesabına ilişkin konsolide edilmiş bilgilerin çevrim
                    içi platformlarda sunulması hizmetinden her biri “Ödeme
                    Hizmeti” sayılır.
                    <br />
                    13.2. Ödeme Hizmetlerinin sunulması Kullanıcının talebine
                    veya onayına tabidir. Kullanıcı Hizmet Arayüzü aracılığıyla
                    talep ettiği Ödeme Hizmeti için işbu Sözleşmede, ilgili
                    Mevzuatta ve/veya Ödeme Hizmeti talebinin veya onayının
                    verildiği aşamada kendisine bildirilen şart ve koşulları
                    kabul etmiş sayılır.
                    <br />
                    13.3. Kullanıcı Ödeme Hizmetleri için yalnızca hukuken
                    kullanmaya hak ve yetkisi olduğu banka hesapları, kredi
                    kartları, banka kartları ve diğer ödeme araçlarını kullanmak
                    suretiyle ödeme hesabına fon aktarabilir. Kullanıcı, hukuka
                    aykırı şekilde kullanılan ödeme araçları nedeniyle üçüncü
                    kişilerin Alışverişlio’ya yöneltebileceği talepler nedeniyle
                    Alışverişlio’nın uğrayabileceği tüm zararları tazmin etmekle
                    yükümlüdür.
                    <br />
                    13.4. Sözleşmede öngörülen şartların Kullanıcı tarafından
                    yerine getirilmesi kaydıyla, ödeme işleminin gönderen veya
                    alıcı tarafından başlatılıp başlatılmadığına bakılmaksızın,
                    ödeme işleminin gerçekleştirilmesi 5549 sayılı Kanun ve
                    diğer Mevzuata aykırılık oluşturmadığı, Mevzuatta aksine
                    hüküm bulunmadığı veya haklı bir neden olmadığı sürece,
                    Alışverişlio ödeme emrini başlatmayı veya yerine getirmeyi
                    reddedemez. Alışverişlio, bir ödeme emrini başlatmayı veya
                    yerine getirmeyi reddettiği takdirde, diğer kanunlarda yer
                    alan bilgi verilmesini engelleyici hükümler ile güvenliği
                    tehdit edici objektif nedenlerin bulunması hali dışında ret
                    gerekçesini ve varsa redde sebep olan hataların ne şekilde
                    düzeltilebileceğini Hizmet Arayüzü ve Kullanıcı tarafından
                    Alışverişlio’da kayıtlı diğer iletişim kanalları üzerinden
                    en kısa zamanda ve her halükarda en geç ödeme emrinin
                    alınmasını izleyen iş günü sonuna kadar müşteriye bildirir.
                    <br />
                    13.5. Alışverişlio Ödeme Hizmeti kapsamında Kullanıcı
                    tarafından onay verilmesi şartına bağlı olarak, o Ödeme
                    Hizmetine ilişkin olarak öngörülen belli bir tutarı Alıcıya
                    aktarmayı taahhüt etmektedir. Kullanıcı, ödeme işleminin
                    gerçekleştirilmesine ilişkin olarak Hizmet Arayüzü üzerinden
                    ve/veya Alışverişlio ile mutabık kalındığı şekilde telefon,
                    tablet, bilgisayar vb gibi iletişim araçları üzerinden onay
                    verdiği takdirde Alışverişlionın Ödeme İşleminin
                    tamamlanması için yetkilendirilmiş sayılacağını kabul eder.
                    <br />
                    13.6. Kullanıcı, ödemenin belirli bir günde, belirli bir
                    dönemin sonunda gerçekleştirilmesi için Ödeme Emri verir
                    ise, (Örneğin fatura ya da aidat ödemeleri için otomatik
                    ödeme talimatı verilmesi vb.) ödeme için kararlaştırılan gün
                    Ödeme Emrinin alınma zamanı olarak kabul edilir. Bu kapsamda
                    Kullanıcı Ödeme Emrini en geç ödeme işleminin
                    gerçekleştirilmesi için kararlaştırılan günden önceki iş
                    gününün sonuna kadar geri alabilecektir.
                    <br />
                    13.7. Gönderen konumundaki Kullanıcı tarafından, Ödeme
                    Hizmetinin onaylanması sırasında belirtilen kimlik
                    tanımlayıcıya uygun şekilde yapılan ödeme işlemleri, doğru
                    Alıcıya yapılmış sayılır. Kullanıcı tarafından belirtilen
                    kimlik tanımlayıcının hatalı olması halinde Alışverişlio,
                    Ödeme İşleminin gerçekleştirilmemesinden veya hatalı
                    gerçekleştirilmesinden sorumlu tutulamaz.
                    <br />
                    13.8. Kullanıcı tarafından ödeme emri iletildikten sonra,
                    aksi taraflar arasında kararlaştırılmadıkça Kullanıcı
                    tarafından geri alınamaz. Ödeme işleminin alıcı tarafından,
                    Alıcı aracılığıyla veya ödeme emri başlatma hizmeti
                    sağlayıcısı tarafından başlatıldığı durumlarda, gönderen
                    konumundaki Kullanıcı ödeme emrini Alışverişlio’ya
                    ilettikten veya ödeme işleminin gerçekleştirilmesi için
                    Alışverişlio’ya onay verdikten sonra ödeme emrini geri
                    alamaz. Doğrudan borçlandırma yöntemiyle yapılan ödeme
                    işleminde, Kullanıcı ödeme emrini en geç fonların hesaba
                    borç kaydedilmesi için kararlaştırılan günden önceki iş günü
                    sonuna kadar geri alabilir. Bu maddede belirtilen süreler
                    geçtikten sonra, ödeme emrinin geri alınması tarafların
                    anlaşmasına bağlıdır. Kullanıcının süre geçtikten sonra
                    ödeme işlemini geri alabilmesi için Alıcının onayı gerekir.
                    Alışverişlio, ödeme emrinin geri alınması için, katlandığı
                    maliyetlerle sınırlı bir ücret talep edebilir.
                    <br />
                    13.9. İşbu Sözleşme kapsamında Kullanıcıya sağlanan ödeme
                    araçları ile ilgili limitler, EK-1’de yer alan ve işbu
                    Sözleşme’nin de eki ve ayrılmaz parçası niteliğindeki olan
                    Ücret Bilgilendirme Listesinde yer almaktadır.
                    <br />
                    13.10. Ödeme hesabına faiz işletilemez, Kullanıcıya süreye
                    veya tutara bağlı herhangi bir menfaat sağlanamaz.
                    <br />
                    14. KARTLAR
                    <br />
                    14.1. Kullanıcı hesabına tanımlanan Kart ile Alışverişlio
                    hesabına erişebilir, ödeme talimatlarını verebilir ve mal
                    ve/veya hizmet alımlarını gerçekleştirebilir.
                    <br />
                    14.2. Kullanıcı, Kart ürün ve hizmet alımı için ödeme
                    yapabilir, ödeme hesabındaki elektronik paranın fona
                    çevrilmesi sonucunda nakit olarak çekebilir; ödeme ve para
                    transferi işlemlerinde, banka ve ATM’lerde Ödeme Hizmetleri
                    için kullanabilir. Alışverişlio tarafından sunulan Kartlar
                    ile işbu maddede sayılan işlemlerin tümü veya bazıları
                    gerçekleştirilebilir.
                    <br />
                    14.3. Kartlar’ın kullanımı, takas ve mahsup işlemleri Kanun
                    ile Kanuna bağlı yönetmelikler ve diğer ilgili mevzuat
                    hükümlerine tabiidir. Mevzuatta herhangi bir değişiklik
                    olması halinde yapılan değişiklikler yürürlük tarihi
                    itibarıyla uygulanır.
                    <br />
                    14.4. Kullanıcının Kart’ın kullanımından doğan sorumluluğu,
                    kartın eline geçtiği veya fiziki varlığı bulunmayan (sanal)
                    kart/cüzdan bilgilerinin kendisine iletildiği andan itibaren
                    başlayacak olup, Kullanıcı bu andan itibaren Kartla ilgili
                    gerekli güvenlik önlemlerini almakla yükümlüdür. Kartın,
                    Kart bilgilerinin kaydedilmesi sebebiyle Kart kullanımını
                    mümkün kılabilecek olan cep telefonu vb elektronik
                    cihazların ve Kart’ın kullanılmasına ilişkin kod numarası,
                    şifre veya kimliği belirleyici diğer yöntemlere dair
                    bilgilerin güvenli bir şekilde korunması ve başkaları
                    tarafından kullanılmasına engel olacak önlemlerin alınması
                    Kullanıcının sorumluluğundadır.
                    <br />
                    14.5. Şehir içi toplu ulaşım ve anlaşmalı alanlara (müze ve
                    ören yerleri gibi) geçiş sistemlerinde kullanılıp işlem
                    limitine anlık yansımayan bakiyeler tespit edilemediğinden,
                    Kartın kayıp/çalıntı/tahrifat gibi nedenlerle kullanılamaz
                    hale gelmesi ya da ilgili hizmeti kullanmamanız durumunda
                    ilgili bakiye Kullanıcıya iade edilemeyecektir.
                    <br />
                    14.6. Kartın yurt dışında kullanımı ile doğacak olan borç ve
                    alacak kayıtları, ilgili Kart Şeması aracılığı ile döviz
                    cinsinden Alışverişlio’ya bildirilir ve harcamalar ilgili
                    komisyon ve masraflarıyla birlikte, Kullanıcının hesabına
                    kaydedildiği tarihte Alışverişlio’nın Mevzuat hükümlerine
                    uygun olarak belirlediği döviz satış kuru veya bu tür
                    harcamalar için tespit edilecek aylık ortalama kur üzerinden
                    Türk Lirasına çevrilir ve yansıtılır. Bu durumda ilgili
                    işlemle ilgili referans döviz kuru Kart Şeması tarafından
                    belirlenir.
                    <br />
                    14.7. İşbu sözleşme kapsamında Kullanıcıya verilmiş olan
                    Kartın Tek Seferlik Ödeme İşlemlerinde kullanılması
                    durumunda, Alışverişlio, Kullanıcıya bir başka Ödeme Hizmeti
                    sağlayıcısıyla yapılmış çerçeve sözleşme temelinde verilmiş
                    veya verilecek olan bilgiyi sağlamakla yükümlü olmamakla
                    birlikte Kullanıcının talebi üzerine, söz konusu ödeme
                    işlemine ilişkin olarak Ödeme İşleminin azami tamamlanma
                    süresi ile ödenmesi gereken toplam ücret ve ücretlerin
                    dökümüne ilişkin bilgileri Kullanıcıya sağlayacak ve mevzuat
                    kapsamında sayılan ve verilmesi öngörülen diğer bilgiler
                    nazarında Kullanıcıyı bilgilendirecektir. Bu kapsamda
                    Kullanıcının işbu bilgilendirmelerin değiştirilmeden
                    saklanmasına ve kullanılmasına imkân verecek şekilde,
                    herhangi bir ücrete tabi olmaksızın, belirlenecek yöntemler
                    ile ve ayda en az 1 (bir) defa yapılmasını talep etme hakkı
                    saklıdır.
                    <br />
                    14.8. Alıcı tarafından veya alıcı aracılığıyla başlatılan
                    kart tabanlı ödeme işlemlerinde, Kullanıcı konumundaki
                    gönderenin ödeme işlemine onay verdiği anda işleme ilişkin
                    tutarın tam olarak bilinmediği durumlarda, Alışverişlio,
                    Kullanıcının ödeme hesabında bulunan fonu, Kullanıcının
                    bloke edilecek tam tutara ilişkin onay verdiği durumlarda
                    bloke edebilecektir.
                    <br />
                    15. CASHBACK PROGRAMI
                    <br />
                    15.1. Cashback programı, Alışverişlio kullanıcılarının
                    Alışverişlio tarafından belirlenen hizmetlerde, işlemlerde,
                    işyerlerinde ve/veya kategorilerde yapılan harcamalarının
                    belirli bir oranının veya belirli bir tutarın Kullanıcının
                    hesabına elektronik para olarak iadesi programıdır.
                    <br />
                    15.2. Cashback programı onaylı veya sözleşmeli Alışverişlio
                    hesabı sahibi Kullanıcılarının katılımına açıktır. Kurumsal
                    Alışverişlio hesap sahipleri ve kurumsal kart kullanıcıları
                    Cashback programından faydalanamaz.
                    <br />
                    15.3. Cashback programı kapsamında her bir kategori ve/veya
                    işyeri için kazanılabilecek azami Cashback tutarı ve oranı
                    Alışverişlio web sitesi ve mobil uygulamasında yayınlanır.
                    Alışverişlio’nın azami tutar ve oranlarda dilediği zaman her
                    türlü değişiklik yapma hakkı saklı olup, azami tutar ve/veya
                    oran değişiklikleri Kullanıcılar tarafından Alışverişlio web
                    sitesi ve mobil uygulama üzerinden takip edilebilecektir.
                    Alışverişlio, işbu değişiklikleri web sitesi ve mobil
                    uygulama üzerinden güncellediği takdirde Kullanıcının ilgili
                    değişiklikten haberdar olduğu kabul edilecektir.
                    <br />
                    15.4. Kullanıcıların daha önce yaptıkları harcamalardan elde
                    ettikleri kazanımlar saklı kalmak kaydıyla; Cashback
                    programı kapsamındaki tüm kategoriler ve/veya işyerleri
                    Alışverişlio tarafından bir bildirime gerek olmaksızın her
                    zaman kaldırılabilir ve/veya değiştirilebilir.
                    Değişikliklerin takibinden Kullanıcılar sorumludur.
                    <br />
                    15.5. Alışverişlio Cashback programı kapsamında belirlenen
                    kategoriler ve/veya işyerlerinde, Kullanıcılar tarafından
                    yapılacak harcamalara istinaden Cashback kazanabilmesi için
                    alt-üst limit belirleme ve dilediği zaman bu limiti
                    değiştirme ve/veya arttırma hakkını haizdir.
                    <br />
                    15.6. Cashback kazanımına sebep olan herhangi bir harcamanın
                    iptali/iadesi halinde, Kullanıcı hesabına Cashback olarak
                    yatırılan tutar, iptal/iade işlemi Kullanıcının elektronik
                    para hesabına yansıdığı anda Alışverişlio tarafından
                    otomatik olarak tahsil edilir. Cashback kazanımına sebep
                    olan herhangi bir harcamanın iptali/iadesi neticesinde,
                    işyeri tarafından söz konusu iadenin Kullanıcıya farklı bir
                    ödeme yöntemi ile sağlanması halinde (nakit, havale, hediye
                    çeki vb.) Kullanıcıya ödenen Cashback tutarı Kullanıcının
                    elektronik para hesabına yansıdığı anda Alışverişlio
                    tarafından otomatik olarak tahsil edilir.
                    <br />
                    15.7. Cashback kazanımının belirli bir kategoride
                    gerçekleşen harcamalar için öngörüldüğü hallerde
                    Alışverişlio, harcama yapılan işyerinin üye işyeri kategori
                    bilgisi ve işlem açıklama bilgisini dikkate alacaktır. Örn.
                    Sinema kategorisinde yapılan harcamalarda Cashback kazanımı
                    öngörülmüş ise, üye işyerinin kategorisinin “sinema”
                    kategorisinde olması ve/veya işlem açıklamasında “sinema”
                    ifadesinin yer alması gerekir. Kategori ve/veya işlem
                    açıklamasında ilgili kategoriye ilişkin yeterli bilgiye
                    ulaşılamaması halinde Alışverişlio tarafından Cashback
                    ödemesi yapılamaz.
                    <br />
                    15.8. Cashback programının suiistimal edildiğinin tespiti
                    veya Alışverişlio tarafından suiistimale yönelik kullanım
                    olduğu kanaatine varılması halinde Alışverişlio Cashback
                    tutarını Kullanıcıya ödememe, ödenmiş ise geri alma hakkını
                    saklı tutar. Cashback programına katılan tüm kullanıcılar
                    suistimale yönelik tespitin Alışverişlio tarafından tek
                    taraflı olarak yapılabileceğini kabul eder.
                    <br />
                    BÖLÜM 5
                    <br />
                    GİZLİLİK VE BİLGİ GÜVENLİĞİ
                    <br />
                    16. VERİLERİN GİZLİLİĞİ
                    <br />
                    16.1. Taraflar, işbu Sözleşme çerçevesinde herhangi bir
                    şekilde diğer Taraf’tan temin edeceği tüm bilgileri devamlı
                    olarak gizli tutacağını, saklayacağını, Sözleşme’de
                    belirtilenden başka bir amaç için kullanmayacağını ve bu
                    bilgileri gizli bilgiler olarak değerlendirerek ilgili
                    Taraf’ın yazılı rızası olmaksızın üçüncü şahıslara (yasal
                    zorunluluklar dışında) ifşa etmeyeceğini beyan ve taahhüt
                    etmektedir.
                    <br />
                    16.2. Kullanıcı, Hizmetin kullanımı için oluşturulan
                    kullanıcı hesabını (ilgili kullanıcı adı ve şifre dahil),
                    sahip olduğu Ödeme Aracını ve Hassas Ödeme Verilerini
                    korumaktan sorumludur ve her ne sebeple olursa olsun
                    Kullanıcı bunları üçüncü kişilerle paylaşmayacaktır. Bahsi
                    geçen bilgiler veya Ödeme Aracının kaybolması, çalınması
                    veya yetkisiz bir şekilde kullanımının söz konusu olması
                    halinde Kullanıcı durumu derhal Alışverişlio’ya
                    bildirecektir.
                    <br />
                    16.3. Gizlilik hükmü Sözleşme’den bağımsız bir taahhüt olup,
                    işbu Sözleşme’nin herhangi bir nedenle hitamından sonra da
                    yürürlükte kalacaktır.
                    <br />
                    17. KİŞİSEL VERİLERİNİN İŞLENMESİ
                    <br />
                    17.1. Kullanıcı, Alışverişlio’ya ait işyeri, ofis veya
                    Alışverişlio’nın Temsilcilerinde doldurmuş olduğu sair
                    formlar, elektronik olarak doldurduğu iletişim formları,
                    işbu sözleşme dahil olmak üzere çevrimiçi kanallar üzerinden
                    Alışverişlio ile yapmış olduğu yazılı veya sözlü
                    iletişimler, elektronik posta sistemi üzerinden
                    Alışverişlio’ ya göndermiş olduğu elektronik posta, faks
                    veya mektup gibi araçlar ile her türlü bilgi ve belgeler,
                    telefon kanalı üzerinden veya doğrudan Alışverişlio ile
                    yapmış olduğu görüşmelerde paylaştığı bilgi ve belgeler ve
                    sair her türlü yolla sözlü, yazılı veya elektronik olarak
                    paylaştığı kişisel veriler dahil ancak bunlarla sınırlı
                    olmamak üzere sair yollarda Alışverişlio’ ya aktarılan tüm
                    kişisel verilerinin Alışverişlio tarafından kısmen veya
                    tamamen, otomatik olan veya olmayan yöntemlerle, Kişisel
                    Verilerin Korunması Kanunu’na uygun olarak toplandığını,
                    Hizmetlerin ve Alışverişlio’nın faaliyetlerinin ifası
                    ve/veya icrasını gerçekleştirmek, hizmetlerin satış
                    süreçlerini planlamak ve/veya icra etmek, ürün ve hizmetleri
                    geliştirmek, çeşitlendirmek, ürün ve hizmetlerin pazarlama
                    ve reklamını yapmak, ilgili kişileri bu faaliyetlerden
                    faydalandırmak için gerekli çalışmaları yapmak, ürün ve
                    hizmetler hakkında bilgilendirmek, ürün ve hizmetler
                    hakkında kullanıcıların memnuniyetin ölçmek, talep ve
                    şikayetleri yönetmek, gerekli kalite ve standart
                    denetimlerini yapmak, bilgi güvenliği süreçlerini planlamak,
                    denetlemek ve/veya icra etmek, ticari ve iş stratejilerini
                    belirlemek ve uygulamak, iş sürekliliği faaliyetlerini
                    planlamak ve/veya icra etmek, finans, muhasebe ve
                    faturalandırmaya ilişkin süreçleri takip etmek, hukuki
                    süreçleri takip etmek, verilerin doğru ve güncel olmasını
                    sağlamak, Şirket operasyonlarının güvenliğini temin etmek,
                    Alışverişlio ile iş ilişkisi içerisinde olan gerçek ya da
                    tüzel kişilerin hukuki, teknik veya ticari iş güvenliğini
                    sağlamak, Şirket prosedürleri gibi amaçlarla
                    işlenebileceğini kabul beyan ve taahhüt eder.
                    <br />
                    17.2. Alışverişlio, kişisel verilerin işlenmesi ile ilgili
                    kuralları ve aydınlatma metnini Internet Sitesi üzerinden
                    yayınlanmaktadır. Kullanıcı, işbu Sözleşmenin
                    akdedilmesinden önce kişisel verilerin işlenmesi ile ilgili
                    kuralların ve aydınlatma metninin kendisine iletildiğini
                    beyan eder.
                    <br />
                    17.3. Alışverişlio, kişisel verilerin işlenmesi ile ilgili
                    kuralları zaman zaman tek taraflı olarak değiştirebilir. Bu
                    durumda, Alışverişlio değişen kuralları Kullanıcıya
                    bildirecektir.
                    <br />
                    BÖLÜM 6
                    <br />
                    SÖZLEŞMENİN SÜRESİ ve SONA ERMESİ
                    <br />
                    18. SÖZLEŞMENİN KURULMASI, YÜRÜRLÜĞÜ VE SÜRESİ
                    <br />
                    18.1. İşbu Sözleşme, Kullanıcının Alışverişlio nezdinde
                    kimlik doğrulama adımlarının tamamlayarak hesap açma onayını
                    Hizmet Arayüzü aracılığıyla ilettiği anda kurulmuş sayılır.
                    Sözleşme kurulduğu anda yürürlüğe girer.
                    <br />
                    18.2. İşbu Sözleşme, belli bir süreye bağlı olmaksızın
                    Alışverişlio veya Kullanıcı tarafından tek taraflı olarak
                    feshedilmedikçe yürürlükte kalmaya devam devam eder.
                    <br />
                    19. CAYMA HAKKI
                    <br />
                    19.1. Kullanıcı, Sözleşme’nin kurulmasından itibaren 14 (on
                    dört) gün içinde herhangi bir gerekçe göstermeden ve cezai
                    şart ödemeden cayma hakkına sahiptir. Kullanıcının cayma
                    hakkını kullanmak istemesi halinde, 14 (ondört) günlük süre
                    içinde Alışverişlio’ya 0850 340 0340 no’lu Çağrı Merkezi
                    yoluyla, yazılı olarak veya kalıcı veri saklayıcı ile cayma
                    hakkını iletmesi gereklidir.
                    <br />
                    19.2. Kullanıcının cayma isteğini Alışverişlio’ya
                    iletmesiyle Sözleşme sona erer ve Kullanıcının cayma
                    isteğini Alışverişlio’ya iletmesini izleyen en geç 7 (yedi)
                    gün içinde Kullanıcıya sunulan tüm Hizmetler durdurulur.
                    <br />
                    20. FESİH
                    <br />
                    20.1. Kullanıcı, sözleşmeyi istediği zaman Sözleşmeyi tek
                    taraflı olarak ve gerekçe göstermeksizin en az 10 (on) gün
                    önceden ihbar etmek koşuluyla feshedebilir. Kullanıcının
                    fesih hakkını kullanmak istemesi halinde, Alışverişlio’ya
                    0850 340 0340 no’lu Çağrı Merkezi yoluyla, yazılı olarak
                    veya kalıcı veri saklayıcı ile cayma hakkını iletmesi
                    gereklidir. Alışverişlio fesih talebini aldıktan sonra,
                    müşterinin sözleşmede yer alan yükümlülüklerini yerine
                    getirmiş olması kaydıyla, mümkün olan en kısa süre
                    içerisinde fesih işleminin gereklerini yerine getirir.
                    <br />
                    20.2. Alışverişlio işbu Sözleşmeyi en az bir ay öncesinden
                    bildirmek suretiyle feshedebilir. Alışverişlio’nın
                    Mevzuattan kaynaklanan yükümlülükleri çerçevesinde
                    sözleşmeyi feshetmesinin gerektiği durumlarda, Alışverişlio
                    işbu Sözleşmeyi bir aylık bildirim süresini beklemeksizin
                    derhal feshedebilir. Alışverişlio fesih bildirimini
                    Kullanıcının bildirdiği iletişim bilgilerini kullanarak
                    kalıcı veri saklayıcı aracılığıyla Kullanıcıya iletir.
                    <br />
                    20.3. Sözleşmenin feshi halinde, Kullanıcı işbu Sözleşme ve
                    Sözleşme kapsamında sunulması öngörülen hizmetlere ilişkin
                    Alışverişlio tarafından katlanılan ve doğrudan müşteri ile
                    ilişkilendirilebilen maliyetlerle sınırlı olacak şekilde
                    fesih ücreti istenebilir. Bir yıldan sonra
                    gerçekleştirilecek fesih için ücret talep edilemez.
                    <br />
                    20.4. Kullanıcının, Hizmetlerin kullanımı için peşin olarak
                    ödemiş olduğu bir tutar varsa, fesih tutarı itibarıyla fazla
                    ödenen tutar Kullanıcıya iade edilir.
                    <br />
                    20.5. Taraflar’dan birinin sözleşmesel yükümlülüklerini
                    ihlal etmesi ve ihlalin düzeltilmesine ilişkin olarak karşı
                    Tarafça iletilen talebe rağmen ilgili ihlalin talebin
                    alındığı tarihi izleyen 1 (bir) hafta içerisinde
                    düzeltilmemesi halinde, işbu Sözleşme ihtarı gönderen
                    Tarafça yazılı olarak iletilecek bir ihbarla derhal tazminat
                    ödemeksizin feshedebilir.
                    <br />
                    20.6. Kullanıcının Alışverişlio tarafından sunulan Hizmeti
                    yasalara veya ahlaka aykırı amaçlarla kullandığının veya
                    yasalara veya ahlaka aykırı mal veya hizmet temin ettiğinin
                    tespit edilmesi halinde veya Alışverişlio’nın mevzuattan
                    kaynaklanan yükümlülükleri çerçevesinde işbu Sözleşme
                    Alışverişlio tarafından derhal herhangi bir tazminat
                    ödemeksizin feshedilebilecektir.
                    <br />
                    20.7. Alışverişlio’nın Kanun kapsamındaki faaliyet izninin
                    herhangi bir nedenle sonlanması halinde, işbu Sözleşme
                    Alışverişlio’ya herhangi bir tazminat yükümlülüğü
                    doğurmaksızın Alışverişlio tarafından yapılan bildirime
                    istinaden feshedilecektir.
                    <br />
                    21. SÖZLEŞMENİN SONA ERMESİ HALİNDE ELEKTRONİK PARANIN GERİ
                    ÖDENMESİ
                    <br />
                    21.1. Sözleşmenin herhangi bir sebeple sona ermesi halinde,
                    ihraç edilmiş elektronik paranın karşılığı olan fon tutarı,
                    Kullanıcının cayma veya fesih hakkını kullanırken bildirdiği
                    Kullanıcıya ait banka hesabına veya başka bir ödeme kuruluşu
                    nezdindeki hesaba veya bir kredi kartına ödeme yapmak
                    suretiyle gönderilir.
                    <br />
                    21.2. Sözleşmenin Alışverişlio tarafından feshedilmesi
                    halinde, Kullanıcı Alışverişlio tarafından fesih ihbarının
                    yapıldığı tarihi izleyen bir ay içinde; Alışverişlio
                    tarafından ihbar edilmeden Sözleşme feshedilmişse feshi
                    izleyen bir aylık süre içinde ihraç edilmiş elektronik
                    paranın karşılığı olan fon tutarının iade edileceği banka
                    hesabını veya ödeme kuruluşu bilgilerini Alışverişlioya
                    bildirmek zorundadır. Kullanıcının bu bildirimi yapmaması
                    halinde, Alışverişlio Kullanıcı tarafından daha önce
                    kendisine ait olduğu bildirilmiş olan bir banka hesabına
                    veya başka bir ödeme kuruluşu nezdindeki hesaba veya bir
                    kredi kartına ödeme yapmak suretiyle ihraç edilmiş
                    elektronik paranın karşılığı olan fon tutarını iade
                    borcundan kurtulur.
                    <br />
                    21.3. İhraç edilmiş elektronik paranın karşılığı olan fon
                    tutarının ödenmemesi ile ilgili olarak Mevzuatın
                    Alışverişlio’ya verdiği yetkiler ve koyduğu yükümlülükler
                    (malvarlığının dondurulması kararları, Alışverişlio’nın
                    takas mahsup hakkını kullanması ve benzeri) saklıdır.
                    <br />
                    BÖLÜM 7
                    <br />
                    ŞİKAYETLER VE UYUŞMAZLIKLAR
                    <br />
                    22. ŞİKAYETLER
                    <br />
                    Kullanıcı, Alışverişlio tarafından sağlanan Hizmetlere
                    ilişkin şikâyet ve itirazları Alışverişlio’nın 0 850 340
                    0340 numaralı Çağrı Merkezine ulaşarak veya
                    [destek@Alışverişlio.com](mailto:destek@xn--Alverilio-wpb02ce.com)
                    adresine eposta yolu ile iletebilecektir. Alışverişlio bu
                    şikayetleri inceleyerek en geç 20 (yirmi) iş günü içinde
                    Kullanıcıya bilgi verecektir.
                    <br />
                    23. UYUŞMAZLIKLAR
                    <br />
                    23.1. Kullanıcı dilerse tüketici işlemleri ve tüketiciye
                    yönelik uygulamalardan doğabilecek uyuşmazlıklara çözüm
                    bulmak amacıyla kurulan Türkiye Ödeme ve Elektronik Para
                    Kuruluşları Birliği nezdinde oluşturulan hakem heyetlerine
                    şahsen veya avukat aracılığıyla başvurabilir. Bu
                    başvuruların nasıl yapılacağı ile ilgili bilgiye
                    [www.todeb.org.tr](http://www.todeb.org.tr/) adresinden
                    ulaşılabilir.
                    <br />
                    23.2. Kullanıcı dilerse tüketici işlemleri ve tüketiciye
                    yönelik uygulamalardan doğabilecek uyuşmazlıklarla ilgili
                    olarak Tüketicinin Korunması Hakkında Kanun çerçevesinde
                    kurulan Tüketici Hakem Heyetlerine veya yetkili ve görevli
                    mahkemelere şahsen veya avukat aracılığıyla başvurabilir.
                    Tüketici Hakem Heyetlerine elektronik ortamda Tüketici Bilgi
                    Sistemi (TÜBİS)
                    ([https://tuketicisikayeti.ticaret.gov.tr](https://tuketicisikayeti.ticaret.gov.tr/))
                    aracılığıyla başvurabilir.
                    <br />
                    BÖLÜM 8
                    <br />
                    DİĞER HÜKÜMLER
                    <br />
                    24. BİLDİRİMLER
                    <br />
                    24.1. İşbu Sözleşmede aksi açıkça belirtilmedikçe
                    Alışverişlio’ya yapılacak tüm bildirimler, Alışverişlionın
                    Madde 1’de belirtilen iletişim bilgileri kullanılmak
                    suretiyle yapılacaktır. Alışverişlio, bazı talepler
                    bakımından ilave Kimlik Doğrulama adımları gerekmesi halinde
                    bildirimin noter kanalıyla yapılmasını şart koşabilir. Bu
                    durumda, ödenmesi gereken tüm tutarla Kullanıcı tarafından
                    karşılanacaktır.
                    <br />
                    24.2. Alışverişlio tarafından yapılacak bildirimler ise
                    Kullanıcının Alışverişlio’ya bildirdiği İletişim Bilgiler
                    kullanılarak yapılacaktır. Kullanıcı bu bilgilerin değişmesi
                    halinde derhal Alışverişlio’ya bildirmekle yükümlüdür.
                    Kullanıcının iletişim bilgilerindeki değişiklikleri
                    bildirmemesi halinde, Alışverişlio Kullanıcı tarafından
                    kendisine bildirilmiş iletişim bilgileri kullanılarak
                    yapacağı bildirim ile bildirim yapma yükümlülüğünü yerine
                    getirmiş sayılır.
                    <br />
                    25. MÜCBİR SEBEP
                    <br />
                    25.1. İşbu Sözleşme’nin imzalandığı tarihte var olmayan ve
                    öngörülemeyen, Tarafların veya tek bir Tarafın çalışma
                    imkanlarını kısmen veya tamamen, geçici veya daimî olarak
                    durduracak şekilde ve derecede meydana gelen beşeri ve doğal
                    afetler, harp, seferberlik, yangın, grev, lokavt vb. -ve
                    bunlarla sınırlı olmayan- haller ile Tarafların kontrolü
                    haricinde zuhur eden sair haller mücbir sebep sayılır.
                    <br />
                    25.2. Mücbir sebepler nedeniyle, Alışverişlio’nın Hizmetleri
                    sunamaması halinde Hizmetlerin sunulmaması nedeniyle
                    Kullanıcı tazminat talep etme hakkı olmayacaktır.
                    <br />
                    26. SÖZLEŞME DEĞİŞİKLİKLERİ
                    <br />
                    26.1. Alışverişlio, işbu Hizmetlere ilişkin kuralları ve
                    koşulları dilediği zaman değiştirme hakkına sahiptir. Söz
                    konusu değişiklikler (gerek Alışverişlio tarafından yapılmış
                    olsun gerekse Kanun ve ilgili sair mevzuattan kaynaklanıyor
                    olsun) işbu Sözleşme koşullarında bir değişikliğe sebep
                    olduğu takdirde (mevzuat değişiklikleri daha kısa bir süreyi
                    öngörmediği müddetçe) Kullanıcıya ilgili değişikliğin
                    yürürlüğe girmesinden 30 (otuz) gün öncesinde değişikliğin
                    kapsamı, yürürlük tarihi ve Kullanıcının fesih hakkına
                    ilişkin bilgileri içeren bir bildirim yapılacaktır.
                    <br />
                    26.2. Kullanıcı, bahsi geçen değişikliği kabul etmediği
                    takdirde Sözleşme’yi herhangi bir ücret ödemeksizin
                    feshedebilecektir. Kullanıcının değişikliğin kendisine
                    bildirilmesinde değişikliğin yürürlüğe gireceği 30 (otuz)
                    günlük süre içinde fesih hakkını kullanmaması halinde
                    değişiklik kabul edilmiş sayılacaktır. Kullanıcı bu kapsamda
                    Alışverişlio’dan herhangi bir talepte bulunamaz. 27.
                    SÖZLEŞMENİN BÜTÜNLÜĞÜ
                    <br />
                    İşbu Sözleşme’nin esaslı olmayan hükümlerinden biri yahut
                    birkaçının kısmen veya tamamen geçersiz addedilmesi,
                    Sözleşme’nin kalan hükümlerinin geçerliliğine etki
                    etmeyecektir. 28. DEVİR
                    <br />
                    Kullanıcı, Alışverişlio’nın yazılı onayı olmadan işbu
                    Sözleşme ile Sözleşmeden doğan hak ve yükümlülüklerini
                    kısmen veya tamamen üçüncü kişilere devir ve temlik edemez.
                    <br />
                    29. HAKLARIN KULLANIMI
                    <br />
                    İşbu Sözleşmenin herhangi bir hükmünün bir Tarafça ihlaline
                    karşın diğer Tarafın herhangi bir işlem yapmamış olması
                    diğer Tarafın haklarından vazgeçtiği anlamına gelmez ve
                    diğer Taraf müteakip ihlal hallerinde de Sözleşmeden doğan
                    haklarını her zaman kullanmak hakkına sahiptir. İşbu
                    Sözleşmeden doğan herhangi bir hakkın ilgili Tarafça
                    süresinde kullanılmaması veya gecikmeli olarak kullanılması,
                    hak sahibi Tarafın söz konusu haktan feragat ettiği anlamına
                    gelmez.
                    <br />
                    29 (yirmi dokuz) maddeden ibaret bu Sözleşme Kullanıcının
                    Alışverişlio nezdinde kimlik doğrulama adımlarının
                    tamamlayarak hesap açma onayını Hizmet Arayüzü aracılığıyla
                    ilettiği anda kurulmuş ve yürürlüğe girmiştir.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default TermsOfService
